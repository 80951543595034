var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.mark)?_c('div',{staticClass:"Membership"},[_c('div',{staticClass:"member-detail"},[_c('van-nav-bar',{staticClass:"navbar",attrs:{"title":"会员中心","right-text":"等级指南","left-arrow":""},on:{"click-left":_vm.onClickLeft,"click-right":_vm.onClickRight}}),_c('div',{staticClass:"ship-card"},[_c('MySwiper',{attrs:{"initial-slide":_vm.active,"swiper-type":_vm.type,"swiper-list":_vm.memberInfoList,"show-scrollbar":false,"space-between":70,"slides-per-view":_vm.auto,"coverflow-effect":_vm.coverflowEffect},on:{"slideChange":_vm.slideChanges},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"card"},[_c('van-image',{staticClass:"banner-image",attrs:{"fit":_vm.imageFit,"src":item.memberLevelImg}}),_c('van-col',{staticClass:"current-grow-value",style:({
                color: item.cardExplatnColour,
              })},[_vm._v("等级成长值 "+_vm._s(item.memberLevelValue))]),_c('van-col',{staticClass:"grow-value",style:({
                color: item.cardExplatnColour,
              }),on:{"click":_vm.ViewDetail}},[(item.nowLevelFlag)?_c('span',[_c('a',{staticStyle:{"font-size":"0.48rem","font-weight":"500","margin-right":"0.1rem"}},[_vm._v(_vm._s(_vm.growthValue))]),_vm._v("成长值 ")]):_c('span',[_vm._v(" "+_vm._s(item.finish ? "已达成该等级" : "未达成该等级")+" ")]),_c('span',{staticStyle:{"margin-left":"0.2rem"}},[_vm._v("明细 "),_c('van-icon',{attrs:{"name":"arrow"}})],1)]),_c('van-button',{staticClass:"complete-status",style:({
                color: item.cardExplatnColour,
                backgroundColor: item.cardNameColour,
              })},[_c('span',[_vm._v(" "+_vm._s(item.nowLevelFlag ? "当前等级" : item.finish ? "已达成" : "未达成")+" ")])])],1)]}}],null,false,1433661959)})],1),_c('van-steps',{attrs:{"active":_vm.active}},_vm._l((_vm.memberInfoList),function(item,index){return _c('van-step',{key:index,class:{
          current: index === _vm.mylevel && String(_vm.mylevel) === _vm.active,
        }},[_c('span',{staticStyle:{"font-weight":"500","font-size":"0.24rem","color":"#111111"},attrs:{"slot":"active-icon"},slot:"active-icon"},[_vm._v(" "+_vm._s(item.memberLevelCode)+" ")]),_c('span',{staticClass:"inactive-icon",attrs:{"slot":"inactive-icon"},slot:"inactive-icon"})])}),1),_c('div',{staticClass:"Membership-interests"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.memberLevelName)+"权益")]),_c('van-row',{staticClass:"interests-list"},_vm._l((_vm.levelInfo),function(item,index){return _c('van-col',{key:index,staticClass:"inters-item",on:{"click":function($event){return _vm.showPouop(item)}}},[_c('van-image',{staticClass:"inters-img",attrs:{"fit":_vm.imageFit,"src":item.icon}}),_c('p',{staticClass:"text"},[_c('span',[_vm._v(_vm._s(item.name))])])],1)}),1)],1)],1),_c('div',{staticClass:"goodlist"},[_c('h3',[_vm._v("我的会员专享")]),_c('VipMain',{attrs:{"good-type":2}})],1),_c('van-popup',{style:({ width: '100%' }),attrs:{"position":"bottom","closeable":"","close-icon-position":"top-left","round":""},model:{value:(_vm.showPopup),callback:function ($$v) {_vm.showPopup=$$v},expression:"showPopup"}},[_c('van-col',{staticClass:"header"},[_c('h3',[_vm._v(_vm._s(_vm.popupcontent.popuptitle))])]),_c('van-col',{staticClass:"desc",domProps:{"innerHTML":_vm._s(_vm.popupcontent.desc)}})],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }